import { clone } from 'ramda'
import * as Pricing from 'nemo-pricing'

const commonFactors = {
  technicalPlatform: [],
  equipmentAge: [],
  equipmentUsage: [],
  operationalEnvironment: []
}

const commonServiceFactors = {
  ...commonFactors,
  VKBUR: []
}

const mbmCallOutAndRepairsEnhancementVars = {
  KCSM_CCC: [],
  KCSM_MAINT_HOURS: [],
  KCSM_MAINT_PRE_BOOK: [],
  KCSM_SERVICE_HOURS: [],
  KCSM_CARE_CALL_OUT_FEE: [],
  KCSM_CO_HOURS: [],
  KCO_SERVICE_HRS_PERFORMANCE: [],
  KCO_PC_RESP: [],
  KCO_PC_RESP_A: [],
  KCSM_RESCUE: [],
  KCO_PC_RESP_E: [],
  KCO_PC_RESP_E_A: [],
  KCO_PC_AVAIL: [],
  KCSM_REPAIR_BUDGET_THR: []
}

const equipmentAndCustomerDetails = {
  segment: [],
  VKBUR: [],
  opportunityCategory: [],
  willingnessToPay: [],
  fundingSector: []
}
const elevator24SevenConnectPriceDrivers = {
  KCSM_24_7_CONNECT_included_numberOfDoors: [],
  KCSM_24_7_CONNECT_alert_numberOfDoors: [],
  KCSM_24_7_CONNECT_speed: [],
  KCSM_LCP_planner_numberOfDoors: [],
  KCSM_LCP_planner_kol_numberOfDoors: [],
  KCSM_LCP_speed: [],
  KCSM_APF_INFO_300: [],
  KCSM_APF_INFO_300_segment: [],
  KCSM_APF_INFO_300_light_numberOfDoors: [],
  KCSM_APF_INFO_300_standard_numberOfDoors: [],
  KCSM_APF_INFO_300_premium_numberOfDoors: [],
  KCSM_APF_INFO_300_speed: [],
  KCSM_APF_INFO_300_OWNER: [],
  KCSM_APF_INFO_300_OWNER_segment: [],
  KCSM_APF_INFO_300_OWNER_KONE_numberOfDoors: [],
  KCSM_APF_INFO_300_OWNER_Customer_numberOfDoors: [],
  KCSM_APF_INFO_300_OWNER_speed: [],
  KCSM_APF_ELEV_CALL_API: [],
  KCSM_APF_ELEV_CALL_API_segment: [],
  KCSM_APF_ELEV_CALL_API_light_numberOfDoors: [],
  KCSM_APF_ELEV_CALL_API_standard_numberOfDoors: [],
  KCSM_APF_ELEV_CALL_API_premium_numberOfDoors: [],
  KCSM_APF_ELEV_CALL_API_speed: [],
  KCSM_APF_SERV_ROBOT_API: [],
  KCSM_APF_SERV_ROBOT_API_segment: [],
  KCSM_APF_SERV_ROBOT_API_light_numberOfDoors: [],
  KCSM_APF_SERV_ROBOT_API_standard_numberOfDoors: [],
  KCSM_APF_SERV_ROBOT_API_premium_numberOfDoors: [],
  KCSM_APF_SERV_ROBOT_API_speed: [],
  KCSM_MOBILE_ELEV_CALL: [],
  KCSM_MOBILE_ELEV_CALL_segment: [],
  KCSM_MOBILE_ELEV_CALL_koneflow_numberOfDoors: [],
  KCSM_MOBILE_ELEV_CALL_wechat_numberOfDoors: [],
  KCSM_MOBILE_ELEV_CALL_whatsapp_numberOfDoors: [],
  KCSM_MOBILE_ELEV_CALL_qrcode_numberOfDoors: [],
  KCSM_MOBILE_ELEV_CALL_speed: [],
  KCSM_APF_EQUIP_STAT_API_light_numberOfDoors: [],
  KCSM_APF_EQUIP_STAT_API_standard_numberOfDoors: [],
  KCSM_APF_EQUIP_STAT_API_premium_numberOfDoors: [],
  KCSM_APF_EQUIP_STAT_API_speed: [],
  KCSM_APF_SERV_INFO_API_light_numberOfDoors: [],
  KCSM_APF_SERV_INFO_API_standard_numberOfDoors: [],
  KCSM_APF_SERV_INFO_API_premium_numberOfDoors: [],
  KCSM_APF_SERV_INFO_API_speed: [],
  KCSM_24_7_VIDEO_VIEW: [],
  KCSM_24_7_VIDEO_VIEW_segment: [],
  KCSM_24_7_VIDEO_VIEW_included_numberOfDoors: [],
  KCSM_24_7_VIDEO_VIEW_speed: [],
  KCSM_AIR_PURIFIER: [],
  KCSM_AIR_PURIFIER_segment: [],
  KCSM_AIR_PURIFIER_standard_numberOfDoors: [],
  KCSM_AIR_PURIFIER_speed: [],
  KCSM_ELEV_MUSIC: [],
  KCSM_ELEV_MUSIC_segment: [],
  KCSM_ELEV_MUSIC_standard_numberOfDoors: [],
  KCSM_ELEV_MUSIC_speed: [],
  KCSM_24_7_CONNECT_opportunityCategory: [],
  KCSM_REMOTE_INTERVENTIONS: [],
  KCSM_REMOTE_INTERVENTIONS_segment: [],
  KCSM_REMOTE_INTERVENTIONS_numberOfDoors: [],
  KCSM_REMOTE_INTERVENTIONS_opportunityCategory: []
}

const elevatorEscalator24SevenPriceDriver = {
  KCSM_APF_EQUIP_STAT_API: [],
  KCSM_APF_EQUIP_STAT_API_segment: [],
  KCSM_APF_SERV_INFO_API: [],
  KCSM_APF_SERV_INFO_API_segment: [],
  KCSM_24_7_CONNECT_opportunityCategory: []
}

const KCSM_SAFETY_TEST = {
  salesOffice: []
}
const KCSM_FIRE_TEST = {
  salesOffice: []
}
const serviceVars = {
  KCSM_CARBON_NEUTRAL: [],
  KCSM_CLEANING: [],
  KCSM_PRESENCE: [],
  KCSM_PREFERRED_TECHNICIAN: [],
  KCSM_CARE_JANITOR: [],
  KCSM_NUMBER_JANITOR_VISIT: [],
  KCSM_GREEN_RESPONSABILITY: [],
  KCSM_CARE_ENV_FEE: [],
  KCSM_PFI_SERVICE: [],
  KCSM_CONTRACT_MANAGEMENT: [],
  KCSM_TRAINING: [],
  KCSM_CARE_INSPECTION: [],
  KCSM_INSP_SCE_TEST: [],
  KCSM_INSP_SCE_COST_COVERAGE: [],
  KCSM_INSP_SCE_FREQUENCY: [],
  KCSM_VOICE_L: [],
  KCSM_DATA_L: [],
  KCSM_24_7_CONNECT: [],
  KCSM_24_7_CONNECT_segment: [],
  KCSM_LCP: [],
  KCSM_LCP_segment: [],
  setupFee: [], // Setup fee for 24/7
  KCSM_FIRE_PACKAGE: [],
  KCSM_DIRECT_LINE: [],
  KCSM_AGREED_CUST_VISITS_YEARLY: [],
  KCSM_VISIT_FREQUENCY_YEARLY: [],
  KCSM_CARE_ONLINE: [],
  KCSM_SAFETY_CERTIFICATE: [],
  KCSM_EMR: [],
  KCSM_CUSTNOTIFY_YN: [],
  KCSM_DISRUPTION_INCIDENT_NOT: [],
  KCSM_SCE_VISIT_NOT: [],
  KCSM_ADDITIONAL_BACKREPORTING: [],
  ZCON_INVOICING: [],
  KCSM_ASSET_MANAGEMENT: [],
  KCSM_AIR_CONDITION_MAINT: [],
  KCSM_INSURANCE_COVERAGE: [],
  CNSM_LABOR_FEE: [],
  KCSM_CUST_PORTAL_SCE: [],
  KCSM_SERVICE_ACCESS_PLATFORM: [],
  KCSM_SAFETY_TEST,
  KCSM_FIRE_TEST,
  KCSM_EXTRA_FEE: [],
  KCSM_CARE_CBM: [],
  KCSM_VOICE_LINK_INST: [],
  KCSM_DCS_MAINT: [],
  KCSM_ACCESS_MAINT: []
}

const contractTermVars = {
  numberOfEquipment: [],
  VLAUFK: [],
  KCSM_BILLING_PLAN: [],
  ZTERM: undefined,
  ZLSCH: [],
  AUGRU: undefined,
  KCSM_INV_ATTACH: [],
  KCMA_PO_FOR_CALLOUTS: undefined,
  KCSM_DISTANCE_RANGE: [],
  KCSM_BILLING_MODE: []
}

const elevator = {
  commonFactors: { ...commonFactors, numberOfDoors: [] },
  commonServiceFactors: { ...commonServiceFactors, numberOfDoors: [] },
  equipmentAndCustomerDetails: { ...equipmentAndCustomerDetails, speed: [], load: [], carDoorType: [] },
  contractTermFactors: { speed: [] }
}

const elevatorContractTerms = {
  ...contractTermVars,
  KCSM_PROJECT_TYPE: clone(elevator.contractTermFactors)
}

const escalator = {
  commonFactors: {
    ...commonFactors,
    numberOfDrives: [],
    escalatorOperationalEnvironment: [],
    rise: [],
    length: [],
    VKBUR: []
  },
  commonServiceFactors: {
    ...commonServiceFactors,
    numberOfDrives: [],
    escalatorOperationalEnvironment: [],
    rise: [],
    length: []
  }
}

const door = {
  commonFactors: {
    doorType: [],
    equipmentAge: [],
    equipmentUsage: [],
    VKBUR: []
  },
  commonServiceFactors: {
    doorType: [],
    equipmentAge: [],
    equipmentUsage: [],
    VKBUR: []
  }
}

export const elevatorDefaultFlexibleVars: Pricing.EncodedVars.ElevatorPriceList = {
  mbm: { ...elevator.commonFactors, additionalDoors: [] },
  allCallOuts: clone(elevator.commonServiceFactors),
  oneCallOuts: clone(elevator.commonServiceFactors),
  twoCallOuts: clone(elevator.commonServiceFactors),
  threeCallOuts: clone(elevator.commonServiceFactors),
  fourCallOuts: clone(elevator.commonServiceFactors),
  fiveCallOuts: clone(elevator.commonServiceFactors),
  sixCallOuts: clone(elevator.commonServiceFactors),
  allCallOutsExt: clone(elevator.commonServiceFactors),
  callOutThresholds: clone(elevator.commonServiceFactors),
  KCSM_CARE_MISUSE: clone(elevator.commonServiceFactors),
  totalCoverage: clone(elevator.commonServiceFactors),
  serviceRepairThresholds: clone(elevator.commonServiceFactors),
  KCSM_SR_SPARE: clone(elevator.commonServiceFactors),
  KCSM_SR_LAB: clone(elevator.commonServiceFactors),
  KCSM_CARE_VANDALISM: clone(elevator.commonServiceFactors),
  KCSM_WEAR_PARTS: clone(elevator.commonServiceFactors),
  KCSM_COMPONENT: clone(elevator.commonServiceFactors),
  KCSM_MPG: clone(elevator.commonServiceFactors),
  KCSM_UPS: clone(elevator.commonServiceFactors),
  // -- local charasteristics
  inspectionService: [],
  // ---- these are not valid anymore?
  callOutLaborThresholds: clone(elevator.commonServiceFactors),
  ...elevator.equipmentAndCustomerDetails,
  ...mbmCallOutAndRepairsEnhancementVars,
  ...serviceVars,
  ...elevator24SevenConnectPriceDrivers,
  ...elevatorContractTerms,
  ...elevatorEscalator24SevenPriceDriver,
  KCSM_DCS_MAINT: [],
  KCSM_ACCESS_MAINT: []
}

export const additionalServicesVars: Pricing.EncodedVars.AdditionalServicesPriceList = {
  KCSM_CARE_INSPECTION: [],
  KCSM_PFI_SERVICE: [],
  KCSM_PRESENCE: [],
  KCSM_CLEANING: [],
  KCSM_VOICE_L: [],
  KCSM_RESCUE: [],
  KCSM_DATA_L: [],
  KCSM_CARE_JANITOR: [],
  KCSM_NUMBER_JANITOR_VISIT: [],
  KCSM_TRAINING: [],
  KCSM_INSP_SCE_FREQUENCY: [],
  KCSM_INSP_SCE_TEST: [],
  KCSM_CUST_PORTAL_SCE: [],
  KCSM_INSP_SCE_COST_COVERAGE: [],
  KCSM_INV_ATTACH: []
}

export const valueAddedServicesVars: Pricing.EncodedVars.VasAddendumVars = {
  KCSM_CARE_ONLINE: [],
  KCSM_24_7_CONNECT: [],
  KCSM_24_7_CONNECT_segment: [],
  KCSM_LCP: [],
  KCSM_LCP_segment: [],
  ...elevator24SevenConnectPriceDrivers,
  ...elevatorEscalator24SevenPriceDriver
}
// TODO: Remove as part of GSM Clean up
export const gsmCampaignVars: Pricing.EncodedVars.GSMCampaignVars = {
  KCSM_24_7_CONNECT: [],
  KCSM_24_7_CONNECT_segment: [],
  KCSM_24_7_CONNECT_included_numberOfDoors: [],
  KCSM_24_7_CONNECT_alert_numberOfDoors: [],
  KCSM_24_7_CONNECT_speed: []
}

export const escalatorDefaultFlexibleVars: Pricing.EncodedVars.EscalatorPriceList = {
  mbm: clone(escalator.commonFactors),
  operationMode: [],
  KCSM_NBR_CO_YEAR: [],
  allCallOuts: clone(escalator.commonServiceFactors),
  allCallOutsExt: clone(escalator.commonServiceFactors),
  callOutThresholds: clone(escalator.commonServiceFactors),
  KCSM_CARE_MISUSE: clone(escalator.commonServiceFactors),
  totalCoverage: clone(escalator.commonServiceFactors),
  serviceRepairThresholds: clone(escalator.commonServiceFactors),
  KCSM_SR_SPARE: clone(escalator.commonServiceFactors),
  KCSM_SR_LAB: clone(escalator.commonServiceFactors),
  KCSM_CARE_VANDALISM: clone(escalator.commonServiceFactors),
  KCSM_WEAR_PARTS: clone(escalator.commonServiceFactors),
  KCSM_COMPONENT: clone(escalator.commonServiceFactors),
  KCSM_MPG: clone(escalator.commonServiceFactors),
  KCSM_UPS: clone(escalator.commonServiceFactors),
  ...mbmCallOutAndRepairsEnhancementVars,
  ...equipmentAndCustomerDetails,
  ...serviceVars,
  ...contractTermVars,
  ...elevatorEscalator24SevenPriceDriver,
  KCSM_HRS_PREMIUM: [],
  KCSM_HRS_PREMIUM_segment: []
}

export const doorDefaultFlexibleVars: Pricing.EncodedVars.DoorPriceList = {
  mbm: clone(door.commonFactors),
  KCSM_NBR_CO_YEAR: [],
  allCallOuts: clone(door.commonServiceFactors),
  callOutThresholds: clone(door.commonServiceFactors),
  serviceRepairThresholds: clone(door.commonServiceFactors),
  totalCoverage: clone(door.commonServiceFactors),
  KCSM_CARE_MISUSE: clone(door.commonServiceFactors),
  KCSM_MPG: clone(door.commonServiceFactors),
  KCSM_UPS: clone(door.commonServiceFactors),
  KCSM_SR_SPARE: clone(door.commonServiceFactors),
  KCSM_SR_LAB: clone(door.commonServiceFactors),
  KCSM_CARE_VANDALISM: clone(door.commonServiceFactors),
  KCSM_WEAR_PARTS: clone(door.commonServiceFactors),
  KCSM_COMPONENT: clone(door.commonServiceFactors),
  ...mbmCallOutAndRepairsEnhancementVars,
  ...equipmentAndCustomerDetails,
  ...serviceVars,
  ...contractTermVars
}

export const resiFlowDefaultFlexibleVars: Pricing.EncodedVars.ResiFlowPriceList = {
  mbm: {
    access300: [],
    visit300: []
  },
  KCSM_APF_ACCESS_300: [],
  KCSM_APF_VISIT_300: [],
  KCSM_CO: [],
  KCSM_SR: [],
  KCSM_CO_HOURS: [],
  KCSM_SERVICE_HOURS: [],
  ...equipmentAndCustomerDetails,
  ...contractTermVars
}
