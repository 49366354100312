import * as R from 'ramda'
import {
  Elevator,
  Equipment,
  EquipmentCategory,
  EquipmentType,
  EquipmentTypeSapCode,
  Escalator,
  OpportunityCustomer,
  OpportunitySfId,
  EquipmentProfile,
  Door,
  ElevatorProfile,
  ResiFlowProfile,
  EquipmentDynamicFieldKey,
  FrontlineConfigJson,
  EquipmentQuestion,
  BuildingQuestion,
  ResiFlow,
  ValidPriceEstimation,
  OfferingQuestion,
  SapKey,
  ChoiceAsKeyValuePair,
  Opportunity
} from '../types/types'
import { VatBreakdown, PriceEstimationBreakdown, FutureEstimationRowPrices } from './pricing/price-breakdown-types'
import { getPriceBasedOnMonthsAndDays } from './prices'
import * as moment from 'moment'
import { formatPrice } from '../client/js/nemo/pricing/utils'
import { applyVatOnPrice } from './pricing/apply-vat'
import { TenderVersionActivity } from 'types/salesforce'
import { EquipmentDate, EquipmentLevelCommercialTerm } from 'nemo-pricing'
import { CommercialTermKey } from 'nemo-pricing'
import * as uuid from 'uuid'
const KI = 'Kone InfoScreen'
const KMS = 'Kone MediaScreen'
export { formatPrice }

export const ELEVATOR: EquipmentCategory = 'elevator'
export const ESCALATOR: EquipmentCategory = 'escalator'
export const DOOR: EquipmentCategory = 'door'
export const getOpportunityFromAccountsById = (
  opportunityId: OpportunitySfId,
  accounts: OpportunityCustomer[] = []
) => {
  for (const account of accounts || []) {
    for (const opportunity of account.opportunities) {
      if (opportunity.id === opportunityId) {
        return opportunity
      }
    }
  }

  return undefined
}

export const sanitizeSalesforceTranslationKey = (key: string | null | undefined) => (key ? key.replace(/\W/g, '') : '')

export const filterEquipmentBy = (equipmentCategory: EquipmentCategory) => (equipment: Equipment[]) =>
  equipment.filter(e => e.equipmentCategory === equipmentCategory)

export const isElevatorProfile = (equipment: EquipmentProfile): equipment is ElevatorProfile =>
  equipment.equipmentCategory === 'elevator'

export const isResiflowProfile = (equipment: EquipmentProfile): equipment is ResiFlowProfile =>
  equipment.equipmentCategory === 'resiflow'

export const isAutowalk = (equipment: Equipment) => equipment.equipmentType === 'autowalk'

export const capitalize = (str: string) => {
  const trimmedString = str.trim()
  return trimmedString.charAt(0).toUpperCase() + trimmedString.slice(1)
}

export const escalatorEquipmentSAPCodes: EquipmentTypeSapCode[] = ['001', '005']

export const elevatorEquipmentSAPCodes: EquipmentTypeSapCode[] = ['006', '003', '004']

type SapEquipmentCode = { [key in EquipmentType]: EquipmentTypeSapCode }
export const getSapEquipmentCode = (equipment: EquipmentType): EquipmentTypeSapCode => sapEquipmentCodes[equipment]

export const sapEquipmentCodes: SapEquipmentCode = {
  autowalk: '001',
  door: '002',
  dumbwaiter: '003',
  elevator: '004',
  escalator: '005',
  stairlift: '006',
  resiflow: '008',
  other: '999'
}

const commonEquipmentFields: Array<keyof Elevator | keyof Escalator | keyof Door> = [
  'koneEquipmentNumber',
  'id',
  'description',
  'year',
  'technicalPlatform',
  'manufacturingSerialnumber',
  'inventoryNumber',
  'equipmentCategory',
  'equipmentType'
]

export const locationFields = [
  'locationName',
  'locationPostalCode',
  'locationCity',
  'locationCountry',
  'locationRegion',
  'equipmentAddress'
]

export const dynamicLocationFields = ['locationPostalCode', 'locationCountry', 'locationRegion']

const equipmentFields: {
  elevator: Array<keyof Elevator>
  escalator: Array<keyof Escalator>
  door: Array<keyof Door>
  resiflow: Array<keyof ResiFlow>
} = {
  elevator: [
    'operationalEnvironment',
    'manufacturer',
    'additionalDoors',
    'doorsCount',
    'ratedSpeed',
    'ratedLoad',
    'controlSystem',
    'controllerType',
    'technicalId',
    'landingDoorType',
    'carDoorType',
    'driveSystem',
    'locationName',
    'locationAddress',
    'equipmentAddress',
    'locationCity',
    'locationRegion',
    'locationCountry',
    'locationPostalCode',
    'apfComponent',
    'koneConnectivityDeviceType'
  ],
  escalator: [
    'operationalEnvironment',
    'manufacturer',
    'escalatorType',
    'operationalEnvironment',
    'length',
    'numberOfDrives',
    'operationalEnvironmentCondition',
    'operationMode',
    'rise',
    'balustradeMaterial'
  ],
  door: ['operationalEnvironment', 'manufacturer', 'doorType', 'workingHeight'],
  resiflow: ['numberOfApartments', 'accessReaders', 'intercoms']
}

export const getEquipmentFields = (equipmentCategory: EquipmentCategory) =>
  R.uniq(commonEquipmentFields.concat(R.path([equipmentCategory], equipmentFields)))

export const isDynamicQuestion = (question: EquipmentQuestion) => {
  switch (question.key) {
    case 'controlSystem':
    case 'controllerType':
    case 'technicalId':
    case 'landingDoorType':
    case 'carDoorType':
    case 'driveSystem':
    case 'balustradeMaterial':
      return true
    default:
      return false
  }
}

export const isDynamicLocationQuestion = (question: EquipmentQuestion | BuildingQuestion) => {
  if (question) {
    switch (question.key) {
      case 'locationCountry':
      case 'locationRegion':
      case 'locationPostalCode':
        return true
      default:
        return false
    }
  } else {
    return false
  }
}

const getAllEquipmentQuestions = (frontlineConfig: FrontlineConfigJson) => [
  ...frontlineConfig.elevatorQuestions,
  ...frontlineConfig.escalatorQuestions,
  ...frontlineConfig.doorQuestions,
  ...frontlineConfig.resiflowQuestions
]

export const getAllDynamicEquipmentQuestions = (frontlineConfig: FrontlineConfigJson) =>
  getAllEquipmentQuestions(frontlineConfig).filter(isDynamicQuestion)

export const getDynamicEquipmentFieldEquipmentCategory = (
  key: EquipmentDynamicFieldKey
): EquipmentCategory | 'location' => {
  switch (key) {
    case 'controlSystem':
    case 'controllerType':
    case 'technicalId':
    case 'landingDoorType':
    case 'carDoorType':
    case 'driveSystem':
      return 'elevator'
    case 'balustradeMaterial':
      return 'escalator'
    case 'locationRegion':
    case 'locationCountry':
    case 'locationPostalCode':
      return 'location'
  }
}

export const getEquipmentType = (equipmentTypeSapCode: EquipmentTypeSapCode): EquipmentType => {
  switch (equipmentTypeSapCode) {
    case '004':
      return 'elevator'
    case '005':
      return 'escalator'
    case '001':
      return 'autowalk'
    case '002':
      return 'door'
    case '003':
      return 'dumbwaiter'
    case '006':
      return 'stairlift'
    case '008':
      return 'resiflow'
    case '999':
      return 'other'
    default:
      return 'elevator'
  }
}

export const mapConnectivityDeviceType = (connectivityDeviceTypeCode: string | null | undefined) => {
  switch (connectivityDeviceTypeCode) {
    case null:
    case undefined:
      return null
    case 'KONE_API':
    case 'CPUC':
      return connectivityDeviceTypeCode
    default:
      return `Other(${connectivityDeviceTypeCode})`
  }
}

export const mapApfComp = (apflabel: string | null | undefined, apfCode: string | null | undefined) => {
  switch (apfCode) {
    case null:
    case undefined:
      return null
    case 'KI':
      return KI
    case 'KMS':
      return KMS
    case 'KI;KMS':
    case 'KMS;KI':
      return `${KMS},${KI}`
    default:
      const extractCode = (apflabel && apflabel.split(';')) || null
      extractCode &&
        extractCode.forEach((c, i) => {
          if (c.toLowerCase() === KI.toLowerCase()) {
            extractCode[i] = KI
          } else if (c.toLowerCase() === KMS.toLowerCase()) {
            extractCode[i] = KMS
          }
        })
      return extractCode && extractCode.length > 0 ? extractCode.join(',') : null
  }
}

export function notUndefined<T>(x: T | undefined): x is T {
  return x !== undefined
}

export function stringify(value: string | number | null | undefined): string {
  return value != null ? value.toString() : ''
}

export function notNull<T>(x: T | null): x is T {
  return x !== null
}

export function escapeRegExp(text: string | RegExp) {
  return text.toString().replace(/[-\/\\^$*+?.()|[\]{}]/g, '\\$&')
}

export const getFlattenedItems = (itemArray: Array<any>) => {
  const flatten = (iterable: Array<any>) => {
    const result: Array<any> = []
    if (!Array.isArray(iterable)) {
      return result.concat([iterable])
    }
    let index = 0
    while (index < iterable.length) {
      const item = iterable[index]
      if (Array.isArray(item)) {
        const innerItems = flatten(item)
        for (const innerItem of innerItems) {
          result.push(innerItem)
        }
      } else {
        result.push(item)
      }
      index += 1
    }
    return result
  }
  if (itemArray === null || !Array.isArray(itemArray)) {
    return []
  } else if (typeof Array.prototype.flat === 'function') {
    return itemArray.flat()
  } else {
    return flatten(itemArray)
  }
}

function getStartEndDate(contractStartMoment: any, yearNumber: number, outputDateFormat: string) {
  const isLeapDate =
    contractStartMoment.isLeapYear() && contractStartMoment.get('month') === 1 && contractStartMoment.get('date') === 29
  // yearNumber could be 0 if it is intital contract start year i.e base year or first year

  const startDate = contractStartMoment
    .clone()
    .add(yearNumber > 0 && isLeapDate ? 1 : 0, 'd')
    .add(yearNumber, 'year')
    .format(outputDateFormat)

  let endDate = contractStartMoment
    .clone()
    .subtract(yearNumber > 0 && isLeapDate ? 0 : 1, 'd')
    .add(yearNumber + 1, 'year')
    .format(outputDateFormat)

  const endDateFormat = moment(endDate, outputDateFormat)
  const isLeapFeb = endDateFormat.isLeapYear() && endDateFormat.get('month') === 1 && endDateFormat.get('date') === 28

  if (isLeapFeb) {
    endDate = moment(`29/02/${moment(endDate, outputDateFormat).get('year')}`, 'DD/MM/YYYY').format(outputDateFormat)
  }
  return { startDate, endDate }
}

export const evaluateContractStartEndDates = (VBEGDAT: string | undefined) =>
  getFuturePriceDates(VBEGDAT, 0, 'YYYY-MM-DD', 'YYYY-MM-DD')

interface StartEndDates {
  startDate: string | null
  endDate: string | null
}

interface StartEndCalenderDates {
  from: string
  to: string
}

// To get calender dates for both proortional and future price enabled
export const getFutureCalendarDates = (
  contractStartDate: string,
  contractAcceptanceDate: string,
  inputDateFormat: string, // here input date format is restricted for YYYY/MM/DD
  outputFormat: string
): StartEndCalenderDates[] => {
  const contractStartMoment: moment.Moment = moment(contractStartDate, inputDateFormat)
  const contractAcceptanceMoment: moment.Moment = moment(contractAcceptanceDate, inputDateFormat)
  const startDateYearValue: number = contractStartMoment.get('year')
  const endDateYearValue: number = contractAcceptanceMoment.get('year')
  if (startDateYearValue === endDateYearValue) {
    return [{ from: contractStartMoment.format(outputFormat), to: contractAcceptanceMoment.format(outputFormat) }]
  }
  return [
    {
      from: contractStartMoment.format(outputFormat),
      to: moment(`${startDateYearValue}-12-31`).format(outputFormat)
    }
  ].concat(
    getFutureCalendarDates(`${startDateYearValue + 1}-01-01`, contractAcceptanceDate, inputDateFormat, outputFormat)
  )
}

export const getFuturePriceDates = (
  contractStartDate: string | undefined,
  yearNumber: number,
  inputDateFormat: string,
  outputDateFormat: string
): StartEndDates => {
  const contractStartMoment = moment(contractStartDate, inputDateFormat)
  if (contractStartMoment.isValid()) {
    return getStartEndDate(contractStartMoment, yearNumber, outputDateFormat)
  }
  return { startDate: null, endDate: null }
}

// To send future price for each years
export const getFuturePriceDatesSAP = (
  contractStartDate: string | undefined,
  yearNumber: number,
  inputDateFormat: string,
  outputDateFormat: string
): { startDate: string | null; endDate: string | null } => {
  const contractStartMoment = moment(contractStartDate ? contractStartDate : '', inputDateFormat)
  return getStartEndDate(contractStartMoment, yearNumber, outputDateFormat)
}

// To check if future price valid year is less that one
export function isPeriodWithinAYear(code: string | undefined) {
  const periodCodeArray = ['Z8', '01', '08', '02']
  return periodCodeArray.includes(code || '')
}

export const isDateValid = (dateVal: string | undefined): boolean => moment(dateVal, 'YYYY-MM-DD', true).isValid()

export const getPriceEstimationBreakdown = (
  priceEstimations: ReadonlyArray<ValidPriceEstimation> = [],
  vatBreakDown: VatBreakdown[],
  isFutureProportionalPriceEnabled: boolean = false
): PriceEstimationBreakdown[] =>
  priceEstimations.map((p: ValidPriceEstimation) => {
    const price = isFutureProportionalPriceEnabled
      ? getPriceBasedOnMonthsAndDays(p.price, p.from || '', p.to || '')
      : p.price
    const futurePrices = applyVatOnPrice(price, vatBreakDown)
    return {
      monthlyPrice: p.price / 12,
      priceEstimationVatExcluded: price,
      priceEstimationVatIncluded: futurePrices.priceWithVat,
      from: p.from,
      to: p.to,
      ...R.reduce<VatBreakdown, any>(
        (acc, curVal) => ({ ...acc, [curVal.key]: curVal.annualVatPrice }),
        {},
        futurePrices.vatPriceBreakdown
      )
    }
  })

export const getSubtotalsForPriceEstimation = (
  priceEstimates: PriceEstimationBreakdown[]
): FutureEstimationRowPrices => ({
  monthlyPrice: priceEstimates.map(p => p.monthlyPrice).reduce(R.add, 0),
  priceEstimationVatExcluded: priceEstimates.map(p => p.priceEstimationVatExcluded).reduce(R.add, 0),
  priceEstimationVatIncluded: priceEstimates.map(p => p.priceEstimationVatIncluded).reduce(R.add, 0),
  VAT: priceEstimates.map(p => p.VAT).reduce(R.add, 0),
  VAT_2: priceEstimates.map(p => p.VAT_2).reduce(R.add, 0),
  VAT_3: priceEstimates.map(p => p.VAT_3).reduce(R.add, 0)
})

export const getVATKeys = (r: FutureEstimationRowPrices) =>
  Object.keys(r).filter((v: keyof FutureEstimationRowPrices) => v.includes('VAT') && r[v] > 0)

export function sanitizeFileName(fileName: string, fileExtension: string, replacement: string = ' ') {
  /* eslint-disable no-control-regex */
  // I need to use control characters inside of this regex as
  // it is matching a file name which can have control characters
  // which shouldn't be allowed in a file name
  const invalidValuesRe = /\b(?:true|false|null|undefined)\b/i
  const illegalRe = /[\/\?<>\\:\*\|":]/g
  const controlRe = /[\x00-\x1f\x80-\x9f]/g
  const reservedRe = /^\.+$/
  const windowsReservedRe = /^(con|prn|aux|nul|com[0-9]|lpt[0-9])(\..*)?$/i
  /* eslint-enable no-control-regex */
  const sanitized = fileName
    .replace(invalidValuesRe, replacement)
    .replace(illegalRe, replacement)
    .replace(controlRe, replacement)
    .replace(reservedRe, replacement)
    .replace(windowsReservedRe, replacement)
    .trim()
  // check total length of file name if greater than 255 then truncate it
  return `${sanitized.substr(0, 255 - fileExtension.length) || '_'}${fileExtension}`
}

export function getOpportunitiesToUnshare(activity: TenderVersionActivity[]): string[] {
  return activity.reduce((opportunityList: string[], v: TenderVersionActivity) => {
    if (
      ['Order Received (Won)', 'Lost', 'No Bid', 'On Hold', 'Cancelled', 'Alternative Declined', 'Alternative'].indexOf(
        v.opportunityStage
      ) >= 0
    ) {
      opportunityList.push(v.opportunityId)
    }
    return opportunityList
  }, [])
}

export const getEarliestAndLatestDate = (eqDate: EquipmentDate, sortType: string): string => {
  if (typeof eqDate === 'object' && eqDate) {
    const headerDate =
      sortType === 'asc'
        ? Object.values(eqDate).sort((a, b) => Number(new Date(a)) - Number(new Date(b)))
        : Object.values(eqDate).sort((a, b) => Number(new Date(b)) - Number(new Date(a)))
    return R.head(headerDate) || ''
  } else {
    return ''
  }
}

export const getBillingPlanToXML = (billingPlan: any): string => {
  let formattedXML = ''
  if (billingPlan && typeof billingPlan === 'object') {
    Object.entries(billingPlan).forEach(([value]) => {
      formattedXML += `${value}`
    })
  }
  return formattedXML
}

export const getOrderReasonToXML = (orderReason: any): string => {
  let formattedXML = ''
  if (orderReason && typeof orderReason === 'object') {
    Object.entries(orderReason).forEach(([key, value]) => {
      if (key === 'AUGRU') {
        value = ''
      }
      formattedXML += `${value}`
    })
  }
  return formattedXML
}

export const getEquipmentWiseCommercialTermData = (
  salesToolId: string,
  equipmentData: EquipmentLevelCommercialTerm | undefined
): string | undefined => (equipmentData ? equipmentData[salesToolId] : equipmentData)

export const getEquipmentBillingPlan = (
  BILLINGPLAN: EquipmentLevelCommercialTerm | string | undefined,
  AUGRU: EquipmentLevelCommercialTerm | string | undefined,
  key: string
): string | undefined => {
  if (!BILLINGPLAN || typeof BILLINGPLAN === 'string') {
    return BILLINGPLAN
  }
  if (!AUGRU || typeof AUGRU === 'string') {
    return AUGRU
  }
  switch (key) {
    case 'KCSM_BILLINGPLAN':
      return getBillingPlanToXML(BILLINGPLAN)
    case 'AUGRU':
      return getOrderReasonToXML(AUGRU)
    default:
      return getBillingPlanToXML(BILLINGPLAN)
  }
}

export const getEquipmentWiseDate = (
  salesToolId: string,
  equipmentDate: EquipmentDate | undefined
): string | undefined => (equipmentDate ? equipmentDate[salesToolId] : equipmentDate)

export const getEquipmentContractDate = (
  contractDates: EquipmentDate | string | undefined,
  key: string
): string | undefined => {
  if (contractDates === undefined || contractDates === null || typeof contractDates === 'string') {
    return contractDates
  }

  switch (key) {
    case 'VBEGDAT':
      return getEarliestAndLatestDate(contractDates, 'asc')
    case 'KCSM_BASE_MONTH_ESCALATION':
      return ''
    default:
      return getEarliestAndLatestDate(contractDates, 'dsc')
  }
}

export const getContractEndDateKey = (frontlineConfig: FrontlineConfigJson): CommercialTermKey | '' => {
  const commercialTermsConfig = frontlineConfig.commercialTerms
  const configuredTerms = commercialTermsConfig.filter(ct => ['VENDDAT', 'VABNDAT'].includes(ct.key))
  if (configuredTerms.length === 1) {
    return configuredTerms[0].key
  } else if (configuredTerms.length === 2) {
    return 'VABNDAT'
  }
  return ''
}

export const getUUID = () => uuid.v4()

export const isServiceAvailable = (sapKey: SapKey, choice: string, questions?: OfferingQuestion[] | null) =>
  questions && questions.find(services => services.key === sapKey && services.choices.includes(choice)) ? true : false

export const winLossReasons: Record<string, string> = {
  buildingNotUsed: 'Building not used',
  callOutRate: 'Call out rate',
  contractualTerms: 'Contractual Terms & Conditions',
  customerRelationship: 'Customer Relationship/Bulking',
  customerSelfMaintenace: 'Customer self-maintenance',
  deliveryTime: 'Delivery Time',
  distributors: 'Distributors',
  ecoEfficiency: 'Eco-Efficiency',
  location: 'Location',
  maintenancePriceConditions: 'Maintenance price/ conditions',
  mod: 'MOD/FURE',
  price: 'Price',
  product: 'Product',
  serviceQuality: 'Service quality'
}

export const getListOfKeys = (choices: ChoiceAsKeyValuePair[]) => choices.map(ch => ch.key)

export function checkIfContractInactive(opportunity: Opportunity): boolean {
  return (
    !!opportunity.contracts?.some(contract => contract.inactiveContract) &&
    opportunity.opportunityCategory === 'Renegotiation'
  )
}
